<template>
  <div class="content">
    <!-- 面包屑 -->
    <div class="crumb">
      <Breadcrumb separator=">">
        <Breadcrumb-item :to="{ path: '/' }">悦心车</Breadcrumb-item>
        <Breadcrumb-item :to="{ path: '/carlist' }">车辆列表</Breadcrumb-item>
        <Breadcrumb-item :to="{ path: `/detail?id=${id}` }"
          >车辆详情</Breadcrumb-item
        >
        <Breadcrumb-item :to="{ path: '/detail/360check' }"
          >360项检测</Breadcrumb-item
        >
      </Breadcrumb>
    </div>
    <div class="check">
      <!-- 头部 -->
      <div class="check-title-box">
        <div class="check-title">
          {{ checktitle }}
        </div>
        <div class="check-person-box">
          <div class="check-person-title-wraper">
            <div class="check-person-title">
              基本信息
            </div>
            <div class="check-person-triangle"></div>
          </div>

          <div class="check-person-info">
            <div class="check-person-info-avatar-wraper">
              <div class="check-person-info-avatar">
                <img :src="baseurl + checkPerson.avatar" alt="" />
              </div>
            </div>
            <div class="check-person-info-person">
              <div class="check-person-info-person-name">
                检测师：{{ checkPerson.name }}
              </div>
              <div class="check-person-info-person-time">
                检测时间：{{ checkPerson.time }}
              </div>
              <div class="check-person-info-person-res">
                检测结论：{{ checkPerson.res }}
              </div>
            </div>
          </div>
        </div>
        <div class="car-basic">
          <div class="car-basic-title-wraper">
            <div class="car-basic-title">
              车辆基本信息
            </div>
            <div class="car-basic-triangle"></div>
          </div>

          <div class="car-basic-table">
            <div
              class="car-basic-table-list"
              v-for="(item, index) in checkBasic"
              :key="index"
            >
              {{ item.name }}:<br />{{ item.msg }}
            </div>
          </div>
        </div>
      </div>

      <div class="details-wraper">
        <!-- 重大事故（45项） -->
        <div class="accident-check">
          <div class="accident-check-img">
            <img :src="baseurl + '/pic/1.png'" alt="" />
            <div
              :class="'item' + (index + 1)"
              style="position: absolute;width: 2.1%;height: 0.63%;border-radius: 2px;background-color: #8BBA28;"
              :style="[{ 'background-color': item.color }]"
              v-for="(item, index) in accident"
              :key="index"
            ></div>
          </div>
        </div>
        <!-- 碰撞检测 -->
        <div class="crash-check">
          <div class="crash-check-img">
            <img :src="baseurl + '/pic/2.png'" alt="" />
            <div
              :class="'item' + (index + 1)"
              style="position: absolute;width: 2.1%;height: 1.35%;border-radius: 2px;background-color: #8BBA28;"
              :style="[{ 'background-color': item.color }]"
              v-for="(item, index) in crash"
              :key="index"
            ></div>
          </div>
        </div>
        <!-- 外观及内饰 -->
        <div class="facade-check">
          <div class="facade-check-img">
            <img :src="baseurl + '/pic/3.png'" alt="" />
            <div
              :class="'item' + (index + 1)"
              style="position: absolute;width:2.1%;height: 0.55%;border-radius: 2px;background-color: #8BBA28;"
              :style="[{ 'background-color': item.color }]"
              v-for="(item, index) in facade"
              :key="index"
            ></div>
          </div>
        </div>
        <!-- 易损易耗 -->
        <div class="wear-check">
          <div class="wear-check-img">
            <img :src="baseurl + '/pic/6.png'" alt="" />
            <div
              :class="'item' + (index + 1)"
              style="position: absolute;width:2.1%;height: 2.4%;border-radius: 2px;background-color: #8BBA28;"
              :style="[{ 'background-color': item.color }]"
              v-for="(item, index) in wear"
              :key="index"
            ></div>
          </div>
        </div>
        <!-- 功能配件 -->
        <div class="funct">
          <div class="funct-img">
            <img :src="baseurl + '/pic/5.png'" alt="" />
            <div
              :class="'item' + (index + 1)"
              style="position: absolute;width:2.1%;height: 0.36%;border-radius: 2px;background-color: #8BBA28;"
              :style="[{ 'background-color': item.color }]"
              v-for="(item, index) in funct"
              :key="index"
            ></div>
          </div>
        </div>
        <!-- 随车工具 -->
        <div class="instrumental">
          <div class="instrumental-img">
            <img :src="baseurl + '/pic/8.png'" alt="" />
            <div
              :class="'item' + (index + 1)"
              style="position: absolute;width:2.1%;height: 2.6%;border-radius: 2px;background-color: #8BBA28;"
              :style="[{ 'background-color': item.color }]"
              v-for="(item, index) in instrumental"
              :key="index"
            ></div>
          </div>
        </div>
        <!-- 系统检测 -->
        <div class="systran">
          <div class="systran-img">
            <img :src="baseurl + '/pic/9.png'" alt="" />
            <div
              :class="'item' + (index + 1)"
              style="position: absolute;width:2.1%;height: 0.57%;border-radius: 2px;background-color: #8BBA28;"
              :style="[{ 'background-color': item.color }]"
              v-for="(item, index) in systran"
              :key="index"
            ></div>
          </div>
        </div>
        <!-- 动态检测 -->
        <div class="move">
          <div class="move-img">
            <img :src="baseurl + '/pic/10.png'" alt="" />
            <div
              :class="'item' + (index + 1)"
              style="position: absolute;width:2.1%;height: 0.86%;border-radius: 2px;background-color: #8BBA28;"
              :style="[{ 'background-color': item.color }]"
              v-for="(item, index) in move"
              :key="index"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { carDetail, inspection360 } from "@/api/index.js";
export default {
  data() {
    return {
      baseurl: "https://www.yuexinche.com",
      id: "",
      checktitle: "悦心车检测报告",
      // 检测人员信息
      checkPerson: {
        avatar: "",
        name: "",
        time: "",
        res: "符合悦心车标准"
      },
      // 检测车辆信息
      checkBasic: [ 
        { name: "品牌", msg: "" },
        { name: "车系", msg: "" },
        { name: "排量", msg: "" },
        { name: "环保标准", msg: "" },
        { name: "初次登记日期", msg: "" },
        { name: "表征里程", msg: "" }
      ],
      // 事故
      accident: [],

      // 碰撞
      crash: [],

      // 外观及内饰
      facade: [],

      // 易损易耗件
      wear: [],

      // 功能配件
      funct: [],

      // 随车工具
      instrumental: [],

      // 主要系统检测
      systran: [],

      // 动态检测
      move: [],
      detailsList: {}
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    //360项车辆检测详情
    this.inspection360();
    // 赋值
    this.assignment();
  },
  methods: {
    // 赋值
    assignment() {
      // 车辆详情
      carDetail({
        id: this.id
      }).then(res => {
        this.detailsList = res.data;
        this.checkPerson.avatar = this.detailsList.inspector_avatar;
        this.checkPerson.name = this.detailsList.inspector_name;
        this.checkPerson.time = this.detailsList.inspect_time;
        this.checkBasic[0].msg = this.detailsList.brand_name;
        this.checkBasic[1].msg = this.detailsList.car_name;
        this.checkBasic[2].msg = this.detailsList.displacement;
        this.checkBasic[3].msg = this.detailsList.emission;
        this.checkBasic[4].msg = this.detailsList.register_time;
        this.checkBasic[5].msg = this.detailsList.travel_km;
      });
    },
    //360项车辆检测详情
    inspection360() {
      inspection360({
        id: this.id
      }).then(res => {
        console.log(res)
        this.accident = res.data[0].options;
        this.accident.forEach(e => {
          if (e.item_value == "完好") {
            e.color = "#8BBA28";
          } else if (e.item_value == "严重变形" || e.item_value == "锈蚀") {
            e.color = "#349DD0";
          } else if (e.item_value == "扭曲" || e.item_value == "水泡") {
            e.color = "#E18110";
          } else if (e.item_value == "更换" || e.item_value == "火烧") {
            e.color = "#E95412";
          } else if (e.item_value == "烧焊") {
            e.color = "#C30D22";
          } else if (e.item_value == "褶皱") {
            e.color = "#843E94";
          }
        });
        this.crash = res.data[1].options;
        this.crash.forEach(e => {
          if (e.item_value == "完好") {
            e.color = "#8BBA28";
          } else if (e.item_value == "已整备") {
            e.color = "#349DD0";
          } else if (e.item_value == "变形" || e.item_value == "异常") {
            e.color = "#E18110";
          } else if (e.item_value == "烧焊" || e.item_value == "轻微磨损") {
            e.color = "#E95412";
          } else if (e.item_value == "钣金" || e.item_value == "磨损") {
            e.color = "#C30D22";
          } else if (e.item_value == "更换") {
            e.color = "#843E94";
          }
        });
        this.facade = res.data[2].options;
        this.facade.forEach(e => {
          if (e.item_value == "完好") {
            e.color = "#8BBA28";
          } else if (e.item_value == "已整备") {
            e.color = "#349DD0";
          } else if (e.item_value == "凹陷" || e.item_value == "污渍") {
            e.color = "#E18110";
          } else if (e.item_value == "裂纹" || e.item_value == "破损") {
            e.color = "#E95412";
          } else if (e.item_value == "划痕") {
            e.color = "#C30D22";
          }
        });
        this.wear = res.data[3].options;
        this.wear.forEach(e => {
          if (e.item_value == "完好") {
            e.color = "#8BBA28";
          } else if (e.item_value == "已整备") {
            e.color = "#349DD0";
          } else if (e.item_value == "需更换") {
            e.color = "#E95412";
          } else if (e.item_value == "需添加") {
            e.color = "#E18110";
          }
        });
        this.funct = res.data[4].options;
        this.funct.forEach(e => {
          if (e.item_value == "完好") {
            e.color = "#8BBA28";
          } else if (e.item_value == "已整备") {
            e.color = "#349DD0";
          } else if (e.item_value == "需更换") {
            e.color = "#E18110";
          } else if (e.item_value == "需检修") {
            e.color = "#E95412";
          }
        });
        this.instrumental = res.data[5].options;
        this.instrumental.forEach(e => {
          if (e.item_value == "完好") {
            e.color = "#8BBA28";
          } else if (e.item_value == "已整备") {
            e.color = "#349DD0";
          } else if (e.item_value == "需更换") {
            e.color = "#E18110";
          } else if (e.item_value == "需检修") {
            e.color = "#E95412";
          }
        });
        this.systran = res.data[6].options;
        this.systran.forEach(e => {
          if (e.item_value == "完好") {
            e.color = "#8BBA28";
          } else if (e.item_value == "已整备") {
            e.color = "#349DD0";
          } else if (e.item_value == "需更换") {
            e.color = "#E18110";
          } else if (e.item_value == "需检修") {
            e.color = "#E95412";
          }
        });
        this.move = res.data[7].options;
        this.move.forEach(e => {
          if (e.item_value == "完好") {
            e.color = "#8BBA28";
          } else if (e.item_value == "已整备") {
            e.color = "#349DD0";
          } else if (e.item_value == "需更换") {
            e.color = "#E18110";
          } else if (e.item_value == "需检修") {
            e.color = "#E95412";
          }
        });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 80px;
  box-sizing: border-box;
  // background-color: #f8f8f8;
  .crumb {
    width: 100%;
    height: 20px;
    margin-top: 50px;
    /deep/ .ivu-breadcrumb {
      color: #333;
      font-weight: 500;
      .ivu-breadcrumb-item-separator {
        color: #333;
        font-weight: 500;
      }
    }

    .ivu-breadcrumb > span:last-child {
      color: #333;
      font-weight: 500;
    }
  }
  .check {
    
    width: 1000px;
    height: auto;
    margin: 0 auto;
    background-color: #fff;

    // 头部
    .check-title-box {
      width: 100%;
      height: auto;
      padding: 0 15px;
      box-sizing: border-box;
      .check-title {
        font-size: 20px;
        height: 60px;
        line-height: 60px;
        color: #333;
        text-align: center;
      }
      .check-person-box {
        .check-person-title-wraper {
          display: flex;
          width: 100%;
          .check-person-title {
            width: auto;
            padding: 0 20px;
            box-sizing: border-box;
            color: #fff;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            background-color: #073881;
            display: inline-block;
            position: relative;
          }
          .check-person-triangle {
            width: 0;
            height: 0;
            border-bottom: 20px solid #073881;
            border-right: 10px solid transparent;
          }
        }

        .check-person-info {
          width: 100%;
          height: 100px;
          display: flex;
          .check-person-info-avatar-wraper {
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            .check-person-info-avatar {
              width: 75px;
              height: 75px;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .check-person-info-avatar > img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .check-person-info-person {
            width: auto;
            height: 100%;
            color: #333;
            font-size: 12px;
            .check-person-info-person-name {
              font-size: 14px;
              height: 40px;
              line-height: 40px;
            }
            .check-person-info-person-time {
              font-size: 12px;
              height: 30px;
              line-height: 30px;
            }
            .check-person-info-person-res {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .car-basic {
        width: 100%;
        height: auto;
        margin-top: 20px;
        .car-basic-title-wraper {
          width: 100%;
          display: flex;
          .car-basic-title {
            width: auto;
            padding: 0 20px;
            box-sizing: border-box;
            color: #fff;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            background-color: #073881;
            display: inline-block;
          }
          .car-basic-triangle {
            width: 0;
            height: 0;
            border-bottom: 20px solid #073881;
            border-right: 10px solid transparent;
          }
        }

        .car-basic-table {
          margin-top: 10px;
          width: 100;
          height: auto;
          border: 1px solid #333;
          font-size: 12px;
          color: #333;
          display: flex;
          flex-wrap: wrap;
          .car-basic-table-list {
            height: auto;
            width: 33.33%;
            padding: 20px 15px;
            line-height: 20px;
            box-sizing: border-box;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
          }
          .car-basic-table-list:nth-child(3n) {
            border-right: none;
          }
        }
      }
    }
    .details-wraper {
      // 45项检测
      .accident-check {
        margin-top: 20px;
        width: 100%;
        .accident-check-img {
          width: 100%;
          position: relative;
          .item1 {
            left: 4.5%;
            top: 8%;
          }
          .item2 {
            left: 13.5%;
            top: 10.2%;
          }
          .item3 {
            left: 16.5%;
            top: 16.5%;
          }
          .item4 {
            left: 7.3%;
            top: 18.5%;
          }
          .item5 {
            left: 42%;
            top: 5.32%;
          }
          .item6 {
            left: 39.5%;
            top: 6.8%;
          }
          .item7 {
            left: 27.6%;
            top: 7.64%;
          }
          .item8 {
            left: 29%;
            top: 9.2%;
          }
          .item9 {
            left: 38%;
            top: 15.6%;
          }
          .item10 {
            left: 37.5%;
            top: 17.8%;
          }
          .item11 {
            left: 95.5%;
            top: 7%;
          }
          .item12 {
            left: 90%;
            top: 8.05%;
          }
          .item13 {
            left: 88%;
            top: 8.99%;
          }
          .item14 {
            left: 96.8%;
            top: 13.9%;
          }
          .item15 {
            left: 10%;
            top: 25.4%;
          }
          .item16 {
            left: 60.8%;
            top: 22.3%;
          }
          .item17 {
            left: 65%;
            top: 22.3%;
          }
          .item18 {
            left: 18%;
            top: 34.8%;
          }
          .item19 {
            left: 8.5%;
            top: 36.2%;
          }
          .item20 {
            left: 8%;
            top: 39.95%;
          }
          .item21 {
            left: 12.1%;
            top: 43.85%;
          }
          .item22 {
            left: 52%;
            top: 35.9%;
          }
          .item23 {
            left: 33.3%;
            top: 46.05%;
          }
          .item24 {
            left: 79%;
            top: 34.25%;
          }
          .item25 {
            left: 83.5%;
            top: 44.88%;
          }
          .item26 {
            left: 0.35%;
            top: 57.8%;
          }
          .item27 {
            left: 28.7%;
            top: 57.7%;
          }
          .item28 {
            left: 57%;
            top: 49.91%;
          }
          .item29 {
            left: 76.7%;
            top: 49.91%;
          }
          .item30 {
            left: 57%;
            top: 60.1%;
          }
          .item31 {
            left: 76.7%;
            top: 60.1%;
          }
          .item32 {
            left: 4.7%;
            top: 71.6%;
          }
          .item33 {
            left: 28.5%;
            top: 71.6%;
          }
          .item34 {
            left: 52%;
            top: 71.6%;
          }
          .item35 {
            left: 76%;
            top: 71.6%;
          }
          .item36 {
            left: 4.7%;
            top: 81.1%;
          }
          .item37 {
            left: 44%;
            top: 75.4%;
          }
          .item38 {
            left: 47%;
            top: 81.6%;
          }
          .item39 {
            left: 97.5%;
            top: 75%;
          }
          .item40 {
            left: 92.3%;
            top: 83%;
          }
          .item41 {
            left: 15.2%;
            top: 87.95%;
          }
          .item42 {
            left: 82%;
            top: 87.95%;
          }
          .item43 {
            left: 15.2%;
            top: 91.45%;
          }
          .item44 {
            left: 82%;
            top: 91.45%;
          }
          .item45 {
            left: 39.8%;
            top: 98.9%;
          }
        }
        .accident-check-img > img {
          width: 100%;
        }
      }
      // 碰撞测试
      .crash-check {
        margin-top: 20px;
        width: 100%;
        .crash-check-img {
          width: 100%;
          position: relative;
          .item1 {
            left: 9.7%;
            top: 10.5%;
          }
          .item2 {
            left: 17.5%;
            top: 15%;
          }
          .item3 {
            left: 9.7%;
            top: 16.8%;
          }
          .item4 {
            left: 9.7%;
            top: 18.2%;
          }
          .item5 {
            left: 14.7%;
            top: 29.91%;
          }
          .item6 {
            left: 81%;
            top: 10.5%;
          }
          .item7 {
            left: 97.2%;
            top: 11.7%;
          }
          .item8 {
            left: 96%;
            top: 14%;
          }
          .item9 {
            left: 90%;
            top: 18.3%;
          }
          .item10 {
            left: 95.4%;
            top: 20.75%;
          }
          .item11 {
            left: 72%;
            top: 28%;
          }
          .item12 {
            left: 74.7%;
            top: 30.5%;
          }
          .item13 {
            left: 14.5%;
            top: 59.3%;
          }
          .item14 {
            left: 31.5%;
            top: 59.3%;
          }
          .item15 {
            left: 51.2%;
            top: 59.3%;
          }
          .item16 {
            left: 69%;
            top: 59.3%;
          }
          .item17 {
            left: 12.4%;
            top: 72.45%;
          }
          .item18 {
            left: 30.7%;
            top: 72.45%;
          }
          .item19 {
            left: 50.8%;
            top: 72.45%;
          }
          .item20 {
            left: 72%;
            top: 72.45%;
          }
          .item21 {
            left: 11.5%;
            top: 83.9%;
          }
          .item22 {
            left: 31%;
            top: 83.9%;
          }
          .item23 {
            left: 49.5%;
            top: 83.9%;
          }
          .item24 {
            left: 70.5%;
            top: 83.9%;
          }
          .item25 {
            left: 12.5%;
            top: 97.9%;
          }
        }
        .crash-check-img > img {
          width: 100%;
        }
      }
      // 外观及内饰
      .facade-check {
        margin-top: 20px;
        width: 100%;
        .facade-check-img {
          width: 100%;
          position: relative;
          .item1 {
            left: 33.8%;
            top: 5.85%;
          }
          .item2 {
            left: 23.5%;
            top: 6.9%;
          }
          .item3 {
            left: 24.5%;
            top: 8.4%;
          }
          .item4 {
            left: 21.3%;
            top: 11.55%;
          }
          .item5 {
            left: 20%;
            top: 13.22%;
          }
          .item6 {
            left: 60%;
            top: 13.16%;
          }
          .item7 {
            left: 74.8%;
            top: 7.24%;
          }
          .item8 {
            left: 76.5%;
            top: 8.4%;
          }
          .item9 {
            left: 79.5%;
            top: 11.75%;
          }
          .item10 {
            left: 78.5%;
            top: 13.15%;
          }
          .item11 {
            left: 20.8%;
            top: 18.19%;
          }
          .item12 {
            left: 18%;
            top: 22.35%;
          }
          .item13 {
            left: 23%;
            top: 23.62%;
          }
          .item14 {
            left: 76.5%;
            top: 16.88%;
          }
          .item15 {
            left: 80.5%;
            top: 22.03%;
          }
          .item16 {
            left: 30.5%;
            top: 26.5%;
          }
          .item17 {
            left: 25.5%;
            top: 30.25%;
          }
          .item18 {
            left: 31.8%;
            top: 31.1%;
          }
          .item19 {
            left: 30.5%;
            top: 32.7%;
            width: 7px;
          }
          .item20 {
            left: 25.5%;
            top: 36.455%;
          }
          .item21 {
            left: 31.8%;
            top: 37.3%;
          }
          .item22 {
            left: 47.5%;
            top: 33.35%;
          }
          .item23 {
            left: 79.6%;
            top: 26.5%;
          }
          .item24 {
            left: 86%;
            top: 30.25%;
          }
          .item25 {
            left: 74.5%;
            top: 31.1%;
          }
          .item26 {
            left: 79.6%;
            top: 32.7%;
          }
          .item27 {
            left: 86%;
            top: 36.5%;
          }
          .item28 {
            left: 74.6%;
            top: 37.3%;
          }
          .item29 {
            left: 33%;
            top: 40%;
          }
          .item30 {
            left: 29%;
            top: 41.3%;
          }
          .item31 {
            left: 31%;
            top: 43.7%;
          }
          .item32 {
            left: 28.7%;
            top: 48.3%;
          }
          .item33 {
            left: 33.5%;
            top: 50.4%;
          }
          .item34 {
            left: 39%;
            top: 51.6%;
          }
          .item35 {
            left: 74.5%;
            top: 40%;
          }
          .item36 {
            left: 76.5%;
            top: 41.3%;
          }
          .item37 {
            left: 75%;
            top: 43.7%;
          }
          .item38 {
            left: 76.5%;
            top: 48.3%;
          }
          .item39 {
            left: 73.8%;
            top: 50.4%;
          }
          .item40 {
            left: 11.5%;
            top: 66.06%;
          }
          .item41 {
            left: 31%;
            top: 60.5%;
          }
          .item42 {
            left: 32%;
            top: 65.85%;
          }

          .item43 {
            left: 85%;
            top: 61%;
          }
          .item44 {
            left: 97%;
            top: 64.68%;
          }
          .item45 {
            left: 83%;
            top: 67.34%;
          }
          .item46 {
            left: 15.7%;
            top: 75.65%;
          }
          .item47 {
            left: 37%;
            top: 75.65%;
          }
          .item48 {
            left: 67.8%;
            top: 75.65%;
          }
          .item49 {
            left: 18%;
            top: 80.4%;
          }
          .item50 {
            left: 82.4%;
            top: 80.4%;
          }
          .item51 {
            left: 82.4%;
            top: 85%;
          }
          .item52 {
            left: 35.1%;
            top: 89.4%;
          }
          .item53 {
            left: 52.1%;
            top: 89.4%;
          }
          .item54 {
            left: 35.1%;
            top: 99.2%;
          }
          .item55 {
            left: 52.1%;
            top: 99.2%;
          }
        }
        .facade-check-img > img {
          width: 100%;
        }
      }
      // 易损易耗
      .wear-check {
        margin-top: 20px;
        width: 100%;
        .wear-check-img {
          width: 100%;
          position: relative;
          .item1 {
            left: 16.5%;
            top: 28.6%;
          }
          .item2 {
            left: 35.5%;
            top: 28.6%;
          }
          .item3 {
            left: 58.2%;
            top: 28.6%;
          }
          .item4 {
            left: 77.8%;
            top: 28.6%;
          }
          .item5 {
            left: 15.2%;
            top: 49.2%;
          }
          .item6 {
            left: 35.8%;
            top: 49.2%;
          }
          .item7 {
            left: 58.6%;
            top: 49.2%;
          }
          .item8 {
            left: 78%;
            top: 49.2%;
          }
          .item9 {
            left: 14%;
            top: 69.98%;
          }
          .item10 {
            left: 13.3%;
            top: 91.16%;
          }
          .item11 {
            left: 40.5%;
            top: 62.2%;
          }
          .item12 {
            left: 37%;
            top: 67.3%;
          }
          .item13 {
            left: 36.4%;
            top: 72.5%;
          }
          .item14 {
            left: 38.3%;
            top: 79.1%;
          }
          .item15 {
            left: 36.5%;
            top: 85.9%;
          }
          .item16 {
            left: 37%;
            top: 92.5%;
          }
          .item17 {
            left: 82%;
            top: 62.3%;
          }
          .item18 {
            left: 85.5%;
            top: 67.3%;
          }
          .item19 {
            left: 86.2%;
            top: 72.6%;
          }
          .item20 {
            left: 84.2%;
            top: 79.1%;
          }
          .item21 {
            left: 86%;
            top: 86%;
          }
          .item22 {
            left: 85.8%;
            top: 92.6%;
          }
        }
        .wear-check-img > img {
          width: 100%;
        }
      }
      // 功能配件
      .funct {
        margin-top: 20px;
        width: 100%;
        .funct-img {
          width: 100%;
          position: relative;
          .item1 {
            left: 43.5%;
            top: 6.28%;
          }
          .item2 {
            left: 35.5%;
            top: 7.13%;
          }
          .item3 {
            left: 10.7%;
            top: 10.86%;
          }
          .item4 {
            left: 44%;
            top: 13.5%;
          }
          .item5 {
            left: 80%;
            top: 7.15%;
          }
          .item6 {
            left: 77.8%;
            top: 10.03%;
          }
          .item7 {
            left: 67.5%;
            top: 10.95%;
          }
          .item8 {
            left: 94%;
            top: 10.88%;
          }
          .item9 {
            left: 86%;
            top: 13.52%;
          }
          .item10 {
            left: 58.3%;
            top: 17%;
          }
          .item11 {
            left: 15.1%;
            top: 20.4%;
          }
          .item12 {
            left: 33.5%;
            top: 20.4%;
          }
          .item13 {
            left: 53.9%;
            top: 20.4%;
          }
          .item14 {
            left: 72.8%;
            top: 20.4%;
          }
          .item15 {
            left: 16.2%;
            top: 23.8%;
          }
          .item16 {
            left: 34.8%;
            top: 23.8%;
          }
          .item17 {
            left: 52.2%;
            top: 23.8%;
          }
          .item18 {
            left: 75%;
            top: 23.8%;
          }
          .item19 {
            left: 16.2%;
            top: 26.84%;
          }
          .item20 {
            left: 33.2%;
            top: 26.84%;
          }
          .item21 {
            left: 47.5%;
            top: 26.84%;
          }
          .item22 {
            left: 62.5%;
            top: 26.84%;
          }
          .item23 {
            left: 76%;
            top: 26.84%;
          }
          .item24 {
            left: 13.5%;
            top: 30.32%;
          }
          .item25 {
            left: 33.5%;
            top: 30.32%;
          }
          .item26 {
            left: 55.3%;
            top: 30.32%;
          }
          .item27 {
            left: 76%;
            top: 30.32%;
          }
          .item28 {
            left: 16.7%;
            top: 33.9%;
          }
          .item29 {
            left: 36%;
            top: 33.9%;
          }
          .item30 {
            left: 55.5%;
            top: 33.9%;
          }
          .item31 {
            left: 73.2%;
            top: 33.9%;
          }
          .item32 {
            left: 17%;
            top: 37.18%;
          }
          .item33 {
            left: 37.5%;
            top: 37.18%;
          }
          .item34 {
            left: 52%;
            top: 37.18%;
          }
          .item35 {
            left: 65.2%;
            top: 37.18%;
          }
          .item36 {
            left: 79.8%;
            top: 37.18%;
          }
          .item37 {
            left: 16.8%;
            top: 40.72%;
          }
          .item38 {
            left: 36.5%;
            top: 40.72%;
          }
          .item39 {
            left: 57%;
            top: 40.72%;
          }
          .item40 {
            left: 77.8%;
            top: 40.72%;
          }
          .item41 {
            left: 5.8%;
            top: 46.54%;
          }
          .item42 {
            left: 24%;
            top: 46.54%;
          }
          .item43 {
            left: 47.8%;
            top: 46.54%;
          }
          .item44 {
            left: 75.5%;
            top: 46.54%;
          }
          .item45 {
            left: 3%;
            top: 49.57%;
          }
          .item46 {
            left: 26.8%;
            top: 49.57%;
          }
          .item47 {
            left: 52.5%;
            top: 49.57%;
          }
          .item48 {
            left: 80.5%;
            top: 49.57%;
          }
          .item49 {
            left: 7%;
            top: 52.65%;
          }
          .item50 {
            left: 28.5%;
            top: 52.65%;
          }
          .item51 {
            left: 53.5%;
            top: 52.65%;
          }
          .item52 {
            left: 79%;
            top: 52.65%;
          }
          .item53 {
            left: 7%;
            top: 56.44%;
          }
          .item54 {
            left: 29.5%;
            top: 56.44%;
          }
          .item55 {
            left: 47%;
            top: 56.44%;
          }
          .item56 {
            left: 73.5%;
            top: 56.44%;
          }
          .item57 {
            left: 7%;
            top: 59.8%;
          }
          .item58 {
            left: 28%;
            top: 59.8%;
          }
          .item59 {
            left: 51.3%;
            top: 59.8%;
          }
          .item60 {
            left: 79%;
            top: 59.8%;
          }
          .item61 {
            left: 24%;
            top: 61.95%;
          }
          .item62 {
            left: 12.5%;
            top: 63.1%;
          }
          .item63 {
            left: 8.3%;
            top: 64.2%;
          }
          .item64 {
            left: 72.8%;
            top: 61.95%;
          }
          .item65 {
            left: 84%;
            top: 63.15%;
          }
          .item66 {
            left: 88.5%;
            top: 64.2%;
          }
          .item67 {
            left: 24%;
            top: 65.5%;
          }
          .item68 {
            left: 12.5%;
            top: 66.7%;
          }
          .item69 {
            left: 8.2%;
            top: 67.8%;
          }
          .item70 {
            left: 72.7%;
            top: 65.5%;
          }
          .item71 {
            left: 84%;
            top: 66.75%;
          }
          .item72 {
            left: 88.5%;
            top: 67.8%;
          }
          // 灯光系统检测
          .item73 {
            left: 1.2%;
            top: 72.55%;
          }
          .item74 {
            left: 0.5%;
            top: 73.35%;
          }
          .item75 {
            left: 4%;
            top: 74.27%;
          }
          .item76 {
            left: 4.2%;
            top: 75.15%;
          }
          .item77 {
            left: 4.2%;
            top: 75.66%;
          }
          .item78 {
            left: 3.2%;
            top: 76.36%;
          }
          .item79 {
            left: 89%;
            top: 71.32%;
          }
          .item80 {
            left: 95%;
            top: 72.94%;
          }
          .item81 {
            left: 97%;
            top: 73.65%;
          }
          .item82 {
            left: 90.6%;
            top: 75.3%;
          }
          .item83 {
            left: 93%;
            top: 76.05%;
          }
          .item84 {
            left: 11%;
            top: 79.5%;
          }
          .item85 {
            left: 29.5%;
            top: 79.5%;
          }
          .item86 {
            left: 52.5%;
            top: 79.5%;
          }
          .item87 {
            left: 74%;
            top: 79.5%;
          }
          .item88 {
            left: 12%;
            top: 83.1%;
          }
          .item89 {
            left: 31.2%;
            top: 83.1%;
          }
          .item90 {
            left: 53.5%;
            top: 83.1%;
          }
          .item91 {
            left: 9.5%;
            top: 89.65%;
          }
          .item92 {
            left: 30%;
            top: 89.65%;
          }
          .item93 {
            left: 53%;
            top: 89.65%;
          }
          .item94 {
            left: 75%;
            top: 89.65%;
          }
          .item95 {
            left: 12%;
            top: 92.7%;
          }
          .item96 {
            left: 31.5%;
            top: 92.7%;
          }
          .item97 {
            left: 56%;
            top: 92.7%;
          }
          .item98 {
            left: 77%;
            top: 92.7%;
          }
          .item99 {
            left: 13.1%;
            top: 95.8%;
          }
          .item100 {
            left: 32%;
            top: 95.8%;
          }
          .item101 {
            left: 53.5%;
            top: 95.8%;
          }
          .item102 {
            left: 76.5%;
            top: 95.8%;
          }
          .item103 {
            left: 13.1%;
            top: 99.5%;
          }
          .item104 {
            left: 29%;
            top: 99.5%;
          }
          .item105 {
            left: 51%;
            top: 99.5%;
          }
          .item106 {
            left: 72%;
            top: 99.5%;
          }
        }
        .funct-img > img {
          width: 100%;
        }
      }
      // 随车工具
      .instrumental {
        margin-top: 20px;
        width: 100%;
        .instrumental-img {
          width: 100%;
          position: relative;
          .item1 {
            left: 15.5%;
            top: 49.3%;
          }
          .item2 {
            left: 48.6%;
            top: 49.3%;
          }
          .item3 {
            left: 74%;
            top: 49.3%;
          }
          .item4 {
            left: 17.5%;
            top: 85.5%;
          }
          .item5 {
            left: 50%;
            top: 85.5%;
          }
          .item6 {
            left: 76%;
            top: 85.5%;
          }
        }
        .instrumental-img > img {
          width: 100%;
        }
      }
      // 主要检测
      .systran {
        margin-top: 20px;
        width: 100%;
        .systran-img {
          width: 100%;
          position: relative;
          .item1 {
            left: 13.5%;
            top: 8.1%;
          }
          .item2 {
            left: 34%;
            top: 8.1%;
          }
          .item3 {
            left: 62%;
            top: 8.1%;
          }
          .item4 {
            left: 78.4%;
            top: 8.1%;
          }
          .item5 {
            left: 15%;
            top: 14%;
          }
          .item6 {
            left: 36.2%;
            top: 14%;
          }
          .item7 {
            left: 60.2%;
            top: 14%;
          }
          .item8 {
            left: 77%;
            top: 14%;
          }
          .item9 {
            left: 16.4%;
            top: 19.15%;
          }
          .item10 {
            left: 36.5%;
            top: 19.15%;
          }
          .item11 {
            left: 55.5%;
            top: 19.15%;
          }
          .item12 {
            left: 76.8%;
            top: 19.15%;
          }
          .item13 {
            left: 15.3%;
            top: 24.1%;
          }
          .item14 {
            left: 36.2%;
            top: 24.1%;
          }
          .item15 {
            left: 55.5%;
            top: 24.1%;
          }
          .item16 {
            left: 75.2%;
            top: 24.1%;
          }
          .item17 {
            left: 15.5%;
            top: 28.85%;
          }
          .item18 {
            left: 36.2%;
            top: 28.85%;
          }
          .item19 {
            left: 52.5%;
            top: 28.85%;
          }
          .item20 {
            left: 76.1%;
            top: 28.85%;
          }
          .item21 {
            left: 16.1%;
            top: 33.7%;
          }
          .item22 {
            left: 35.5%;
            top: 33.7%;
          }
          .item23 {
            left: 52.5%;
            top: 33.7%;
          }
          .item24 {
            left: 76.5%;
            top: 33.7%;
          }
          .item25 {
            left: 16.2%;
            top: 37.95%;
          }
          .item26 {
            left: 38.2%;
            top: 37.95%;
          }
          .item27 {
            left: 57.5%;
            top: 37.95%;
          }
          .item28 {
            left: 77.2%;
            top: 37.95%;
          }
          .item29 {
            left: 16.2%;
            top: 42.3%;
          }
          .item30 {
            left: 37%;
            top: 42.3%;
          }
          .item31 {
            left: 57%;
            top: 42.3%;
          }
          .item32 {
            left: 76%;
            top: 42.3%;
          }
          .item33 {
            left: 16%;
            top: 48%;
          }
          .item34 {
            left: 37.5%;
            top: 48%;
          }
          .item35 {
            left: 54.5%;
            top: 48%;
          }
          .item36 {
            left: 76.2%;
            top: 48%;
          }
          .item37 {
            left: 18%;
            top: 52.8%;
          }
          .item38 {
            left: 36.6%;
            top: 52.8%;
          }
          .item39 {
            left: 53.5%;
            top: 52.8%;
          }
          .item40 {
            left: 75.5%;
            top: 52.8%;
          }
          .item41 {
            left: 17.5%;
            top: 57.78%;
          }
          .item42 {
            left: 38%;
            top: 57.78%;
          }
          .item43 {
            left: 57.5%;
            top: 57.78%;
          }
          .item44 {
            left: 75.8%;
            top: 57.78%;
          }
          .item45 {
            left: 17.5%;
            top: 62.9%;
          }
          .item46 {
            left: 39%;
            top: 62.9%;
          }
          .item47 {
            left: 56.5%;
            top: 62.9%;
          }
          .item48 {
            left: 75%;
            top: 62.9%;
          }
          .item49 {
            left: 15.5%;
            top: 68.63%;
          }
          .item50 {
            left: 37%;
            top: 68.63%;
          }
          .item51 {
            left: 55.3%;
            top: 68.63%;
          }
          .item52 {
            left: 76%;
            top: 68.63%;
          }
          .item53 {
            left: 17.8%;
            top: 73.84%;
          }
          .item54 {
            left: 34%;
            top: 73.84%;
          }
          .item55 {
            left: 53.1%;
            top: 73.84%;
          }
          .item56 {
            left: 73.5%;
            top: 73.84%;
          }
          .item57 {
            left: 15%;
            top: 79.1%;
          }
          .item58 {
            left: 35.2%;
            top: 79.1%;
          }
          .item59 {
            left: 53.7%;
            top: 79.1%;
          }
          .item60 {
            left: 75.5%;
            top: 79.1%;
          }
          .item61 {
            left: 16.5%;
            top: 84.1%;
          }
          .item62 {
            left: 25%;
            top: 88.3%;
          }
          .item63 {
            left: 18%;
            top: 89.25%;
          }
          .item64 {
            left: 14.5%;
            top: 91.2%;
          }
          .item65 {
            left: 25%;
            top: 94.25%;
          }
          .item66 {
            left: 18%;
            top: 95.2%;
          }
          .item67 {
            left: 14.5%;
            top: 97.13%;
          }
          .item68 {
            left: 75.2%;
            top: 88.3%;
          }
          .item69 {
            left: 82.5%;
            top: 89.3%;
          }
          .item70 {
            left: 85.8%;
            top: 91.22%;
          }
          .item71 {
            left: 75.3%;
            top: 94.3%;
          }
          .item72 {
            left: 82.5%;
            top: 95.3%;
          }
          .item73 {
            left: 85.5%;
            top: 97.2%;
          }
        }
        .systran-img > img {
          width: 100%;
        }
      }
      // 动态检测
      .move {
        margin-top: 20px;
        width: 100%;
        .move-img {
          width: 100%;
          position: relative;
          .item1 {
            left: 14.5%;
            top: 13.3%;
          }
          .item2 {
            left: 34.1%;
            top: 13.3%;
          }
          .item3 {
            left: 54.3%;
            top: 13.3%;
          }
          .item4 {
            left: 74%;
            top: 13.3%;
          }
          .item5 {
            left: 15%;
            top: 20.8%;
          }
          .item6 {
            left: 36.8%;
            top: 20.8%;
          }
          .item7 {
            left: 57.5%;
            top: 20.8%;
          }
          .item8 {
            left: 73.5%;
            top: 20.8%;
          }
          .item9 {
            left: 13.2%;
            top: 29.3%;
          }
          .item10 {
            left: 32.5%;
            top: 29.3%;
          }
          .item11 {
            left: 15%;
            top: 41.6%;
          }
          .item12 {
            left: 33.1%;
            top: 41.6%;
          }
          .item13 {
            left: 54%;
            top: 41.6%;
          }
          .item14 {
            left: 73.8%;
            top: 41.6%;
          }
          .item15 {
            left: 17.6%;
            top: 49.9%;
          }
          .item16 {
            left: 34.3%;
            top: 49.9%;
          }
          .item17 {
            left: 54.1%;
            top: 49.9%;
          }
          .item18 {
            left: 75.4%;
            top: 49.9%;
          }
          .item19 {
            left: 16.5%;
            top: 56.5%;
          }
          .item20 {
            left: 28.3%;
            top: 70.4%;
          }
          .item21 {
            left: 51.1%;
            top: 70.4%;
          }
          .item22 {
            left: 4.8%;
            top: 87.4%;
          }
          .item23 {
            left: 27.8%;
            top: 87.4%;
          }
          .item24 {
            left: 50.7%;
            top: 87.4%;
          }
          .item25 {
            left: 77.7%;
            top: 87.4%;
          }
          .item26 {
            left: 4.8%;
            top: 98.4%;
          }
          .item27 {
            left: 26.5%;
            top: 98.4%;
          }
          .item28 {
            left: 51.5%;
            top: 98.4%;
          }
          .item29 {
            left: 82.2%;
            top: 98.4%;
          }
        }
        .move-img > img {
          width: 100%;
        }
      }
    }
  }
}
</style>
